import "./Cookies.scss";

import React, { useEffect, useState } from "react";

import CloseIcon from "../../../img/decoration/close.svg";
import SsrCookie from "ssr-cookie";

const cookie = new SsrCookie();

const Cookies = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const gdprCookieValue = cookie.get(
      "gatsby-plugin-google-analytics-gdpr_cookies-enabled"
    );
    if (!gdprCookieValue || gdprCookieValue === "false") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);

  const handleClick = () => {
    // Less than 24 Months, GDPR compliant for Google Analitics
    cookie.set("gatsby-plugin-google-analytics-gdpr_cookies-enabled", "true", {
      expires: 720,
    });
    setIsVisible(false);
  };
  return (
    <section
      className={"Cookies-gdpr"}
      style={{ display: isVisible ? "block" : "none" }}
    >
      <div className="container">
        <p>
          Nous utilisons des cookies pour vous garantir la meilleure expérience
          sur notre site web.
        </p>
        <button className="button" onClick={() => handleClick()}>
          Accepter
        </button>
        <button className="button-close" onClick={() => setIsVisible(false)}>
          <img src={CloseIcon} alt="" />
        </button>
      </div>
    </section>
  );
};

export default Cookies;
