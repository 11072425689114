import "../all.scss";
import "./Layout.scss";

import React, { useEffect } from "react";

import Cookies from "../sections/Cookies/Cookies.js";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import { ThemeProvider } from "styled-components";
import theme from "../../theme/theme.js";
import useSiteMetadata from "../SiteMetadata";
import { withPrefix } from "gatsby";

const TemplateWrapper = ({ children }) => {
  const {
    site: {
      siteMetadata: { title, description },
    },
    settings,
  } = useSiteMetadata();

  useEffect(() => {
    console.log(
      "%c==> Developed by BAHRI Benjamin http://benjamin-bahri.fr for BuddyBuddy agency http://buddybuddy.io <==",
      "color: red; font-size: 16px"
    );
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Helmet>
          <html lang="fr" />
          <title>{title}</title>
          <meta name="description" content={description} />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${withPrefix("/")}img/apple-touch-icon.png`}
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix("/")}img/favicon-32x32.png`}
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix("/")}img/favicon-16x16.png`}
            sizes="16x16"
          />

          <link
            rel="mask-icon"
            href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
            color="#ff4400"
          />
          <meta name="theme-color" content="#fff" />

          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={title} />
          <meta property="og:url" content="/" />
          <meta
            property="og:image"
            content={`${withPrefix("/")}img/og-image.jpg`}
          />
        </Helmet>
        <Navbar />
        <div className="layout">{children}</div>
        <Footer {...settings.frontmatter} />
        <Cookies />
      </div>
    </ThemeProvider>
  );
};

export default TemplateWrapper;
