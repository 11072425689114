import "./Navbar.scss";

import React, { useEffect, useState } from "react";
import {
  StyledGrid,
  useDocumentScrollThrottled,
  useWindowSize,
} from "../../helpers/helpers.js";

import BurgerIcon from "../../img/burger.svg";
import CrossIcon from "../../img/cross.svg";
import { Link } from "gatsby";
import { Row } from "react-styled-flexboxgrid";
import cn from "classnames"
import logo from "../../img/logo-plans-skals.svg";

const Navbar = () => {
  const MINIMUM_SCROLL = 300;
  const TIMEOUT_DELAY = 50;
  const [shouldHideNav, setShouldHideNav] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { isMobile } = useWindowSize();
  const toggleHamburger = () => {
    setIsActive(!isActive);
  };

  useDocumentScrollThrottled(({ previousScrollTop, currentScrollTop }) => {
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL / 10;
    setTimeout(() => {
      setShouldHideNav(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  useEffect(() => {
    if (document !== "undefined") {
      document.body.style.overflow = isActive ? "hidden" : "auto";
    }
  }, [isActive]);
  return (
    <StyledGrid
      fluid={true}
      className={cn(
        "navbar",
        { "navbar--up": shouldHideNav }
      )}
      role="navigation"
      aria-label="main-navigation"
    >
      <Row between={"md"} className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Retourner à l'accueil">
            <img src={logo} alt="Logo Plans Skals - Atelier de plans - Paris" />
          </Link>
          <div
            className={`navbar-burger`}
            onClick={() => toggleHamburger()}
            role="button"
            tabIndex={0}
            onKeyDown={() => toggleHamburger()}
            aria-label="Bouton ouverture menu mobile"
          >
            <img src={isActive && isMobile ? CrossIcon : BurgerIcon} alt="" />
          </div>
        </div>

        <ul
          className={`navbar-menu ${isMobile && isActive ? "is-active" : ""}`}
        >
          {/* {isMobile && ( */}
          <li>
            <Link className="navbar-item" to="/" activeClassName="active">
              Accueil
            </Link>
          </li>
          {/* )} */}
          <li>
            <Link className="navbar-item" to="/about/" activeClassName="active">
              L'atelier
            </Link>
          </li>
          <li>
            <Link
              className="navbar-item"
              to="/projects/"
              activeClassName="active"
              partiallyActive={true}
            >
              Nos projets
            </Link>
          </li>
          <li>
            <Link
              className="navbar-item"
              to="/blog/"
              activeClassName="active"
              partiallyActive={true}
            >
              Découvertes
            </Link>
          </li>
          <li>
            <Link
              className="navbar-item"
              to="/contact/"
              activeClassName="active"
              partiallyActive={true}
            >
              Contact
            </Link>
          </li>
        </ul>
      </Row>
    </StyledGrid>
  );
};

export default Navbar;
