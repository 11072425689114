import { useEffect, useState } from "react";

import { Grid } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { throttle } from "lodash";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    isMobile: undefined,
  });
  useEffect(() => {
    if (typeof window !== "undefined") {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
          isMobile: window.innerWidth <= 768,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);
  return windowSize;
};

export const useMousePosition = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const setFromEventMouse = (e) =>
      setPosition({
        x: e.clientX / window.innerWidth - 0.5,
        y: e.clientY / window.innerHeight - 0.5,
      });

    window.addEventListener("mousemove", setFromEventMouse);

    return () => {
      window.removeEventListener("mousemove", setFromEventMouse);
    };
  }, []);

  return position;
};

export const useDocumentScrollThrottled = (callback) => {
  const [, setScrollPosition] = useState(0);
  let previousScrollTop = 0;

  function handleDocumentScroll() {
    const { scrollTop: currentScrollTop } =
      typeof window === "undefined" || !window.document
        ? 0
        : document.documentElement || document.body;
    setScrollPosition((previousPosition) => {
      previousScrollTop = previousPosition;
      return currentScrollTop;
    });
    callback({ previousScrollTop, currentScrollTop });
  }

  const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 250); // => 30fps || 16: 60fps

  useEffect(() => {
    window.addEventListener("scroll", handleDocumentScrollThrottled);
    return () =>
      window.removeEventListener("scroll", handleDocumentScrollThrottled);
  }, [handleDocumentScrollThrottled]);
};

export const StyledGrid = styled(Grid)`
  @media (max-width: 768px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`;
