import { graphql, useStaticQuery } from "gatsby";

const useSiteMetadata = () => {
  const { site, settings } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
          }
        }
        settings: markdownRemark(
          frontmatter: { templateKey: { eq: "settings" } }
        ) {
          frontmatter {
            address
            contact
            instagramLink
            pinterestLink
            linkedInLink
          }
        }
      }
    `
  );
  return {site, settings};
};

export default useSiteMetadata;
