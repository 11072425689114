import "./Footer.scss";

import { Col, Row } from "react-styled-flexboxgrid";

import { Link } from "gatsby";
import React from "react";
import { StyledGrid } from "../../helpers/helpers.js";

const Footer = ({
  address,
  contact,
  instagramLink,
  pinterestLink,
  linkedInLink,
}) => {
  const year = new Date().getFullYear();
  return (
    <footer className="Footer">
      <StyledGrid>
        <Row>
          <Col
            xs={12}
            sm={6}
            xsOffset={0}
            md={3}
            mdOffset={1}
            className="Footer-item"
          >
            <h3>L'atelier</h3>
            <p>{address}</p>
          </Col>
          <Col
            xs={12}
            sm={6}
            xsOffset={0}
            md={3}
            mdOffset={1}
            className="Footer-item"
          >
            <h3>Contact</h3>
            <p>{contact}</p>
          </Col>
          {/* <Col
            xs={12}
            sm={6}
            xsOffset={0}
            md={2}
            mdOffset={1}
            className="Footer-item"
          >
            <h3>Un rappel ?</h3>
            <p>
              Téléchargez notre
              <br />
              <a
                href="#carteDeVisiteLien"
                title="Télécharger carte de visite"
                className="underlined"
              >
                carte de visite
              </a>
            </p>
          </Col> */}
          <Col
            xs={12}
            sm={6}
            xsOffset={0}
            md={3}
            mdOffset={1}
            className="Footer-item"
          >
            <h3>Réseaux</h3>
            <p>
              <a href={pinterestLink} target={"blank"} title="Pinterest Skals">
                Pinterest
              </a>
              <br />
              <a href={instagramLink} target={"blank"} title="Instagram Skals">
                Instagram
              </a>
              <br />
              <a href={linkedInLink} target={"blank"} title="Linkedin Skals">
                Linkedin
              </a>
            </p>
          </Col>
        </Row>
        <Row center={"xs"}>
          <Col xs={12} className="Footer-copyrights">
            © Skals - {year}
            <br />
            <Link to="/mention" title="Mentions légales - Skals">
              Mentions légales
            </Link>
          </Col>
        </Row>
      </StyledGrid>
    </footer>
  );
};

export default Footer;
